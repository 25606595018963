function About() {
  return (
    <div class="about">
      <p>
        Emeka Acquaye is a Brand designer, Focused on crafting purposeful
        brands, expressing executions through visual and motion design.
        Currently leading design at E/A Design Studio.
      </p>
    </div>
  );
}

export default About;
