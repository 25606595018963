export default function CarouselState(props) {
  return (
    <div class="slide-track">
      <div class="slide">{props.display}</div>

      <div class="slide">{props.display}</div>

      <div class="slide">{props.display}</div>

      <div class="slide">{props.display}</div>

      <div class="slide">{props.display}</div>

      <div class="slide">{props.display}</div>

      <div class="slide">{props.display}</div>

      <div class="slide">{props.display}</div>

      <div class="slide">{props.display}</div>
    </div>
  );
}
