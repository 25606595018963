export default function Connect() {
  return (
    <div class="connect">
      <p>
        <a
          href="https://calendly.com/iamacquaye/initial-call?lt_utm_source=lt_share_link&month=2023-04"
          target="_blank"
        >
          Let's work together
        </a>
      </p>
    </div>
  );
}
